@import "@styles/mixins";
@import "@styles/variables";

.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0 100px;
  gap: 60px;
  overflow: hidden;

  @include non-desktop {
    gap: 24px;
    padding: 32px 0 24px;
  }
}

.headlinesContainer {
  max-width: 900px;

  @include non-desktop {
    max-width: 100%;
  }
}

.statContainer {
  width: 120px;

  @include non-desktop {
    width: auto;
  }
}

.stats {
  padding: 24px 48px;
  align-items: start;

  @include desktop-all {
    background-color: var(--colorV2-light-green);
    border-radius: 120px;
  }

  @include non-desktop {
    padding: 24px 0;
    justify-content: space-between;
    width: 100%;
  }
}

.stat {
  color: var(--colorV2-dark-green);
}

.tangledManContainer {
  z-index: 5;
  @include desktop-all {
    position: absolute;
    bottom: -20px;
    left: 0;
    text-align: end;
  }
  @include non-desktop {
    bottom: -20px;
    left: 0;
    text-align: end;
  }
}

.tangledMan {
  object-fit: contain;
  width: auto;

  @include desktop-small {
    height: 220px;
  }

  @include desktop-wide {
    height: 300px;
  }

  @include tablet {
    height: 180px;
  }

  @include mobile-all {
    height: 120px;
  }
}

.yellowShoeContainer {
  // this is all a bit fidgety, to get the illustrations balanced
  position: relative;
  width: 100%;
  height: 100%;

  @include mobile-all {
    display: none;
  }
}

.shoe {
  position: absolute;
  top: 0;
  left: 80%;
  object-fit: contain;
}

.curiosities {
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, WebKit-based browsers */
  }
}

.curiositiesWrapper {
  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 1;
    background: linear-gradient(
      to right,
      var(--colorV2-grey-light) 30%,
      transparent
    );
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: scaleX(-1);
  }

  @include non-desktop {
    &::before,
    &::after {
      display: none;
    }
  }
}

.curiosityLink {
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-decoration: none;
  color: var(--colorV2-darkest-green);
  flex: 0 0 20%;

  min-width: 180px;
  max-width: 300px;

  @include tablet {
    min-width: 150px;
    max-width: 200px;
  }

  @include mobile-all {
    min-width: 140px;
    max-width: 160px;
  }
}

.curiosity {
  flex: 1;
  width: 100%;

  &:focus,
  &:hover {
    color: var(--colorV2-dark-green);
  }
}

.curiosityImageContainer {
  position: relative;
  height: 140px; /* Match your desired image height */
  width: 100%; /* Ensure this matches the parent's width or is set appropriately */
  display: flex;
  justify-content: center; /* Center horizontally in case of non-absolute fallback */
  @include non-desktop {
    height: 88px;
  }
}

.curiosityImage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto; /* Center the image horizontally */
  object-fit: contain;
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
}

.hiddenImage {
  opacity: 0;
}

.scrollButton {
  z-index: 5;
  svg {
    width: 24px;
    height: 24px;
  }
}

.overlayScrollButton {
  @extend .scrollButton;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: all;
  z-index: 2;
  margin: 0 24px;

  @include non-desktop {
    display: none;
  }
}

.overlayScrollButtonLeft {
  @extend .overlayScrollButton;
  left: 0;
}

.overlayScrollButtonRight {
  @extend .overlayScrollButton;
  right: 0;
}
