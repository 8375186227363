@import "@styles/variables";
@import "@styles/placeholders";

.dropdownButton {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
  color: var(--colorV2-black);
  background: var(--colorV2-beige);
  min-width: 200px;

  &:hover,
  &:focus {
    background: var(--colorV2-beige-over);
  }

  &:focus-visible {
    outline-color: var(--colorV2-beige);
  }
}

.dropdownContent {
  margin-top: 5px;
  background-color: var(--white);
  border-radius: 12px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  min-width: 200px;
}

.dropdownItem {
  padding: 5px 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;

  &:hover,
  &:focus {
    background: var(--colorV2-grey-light);
  }

  &:focus-visible {
    outline: none;
  }
}

.contentContainer {
  border-radius: 12px;
  background-color: var(--colorV2-beige);
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-size-3);

  a:not([type="button"]) {
    @extend %darkGreenUnderlineLink;
  }

  h3 {
    font-weight: 600;
  }

  b {
    font-weight: 500;
  }
}
