@import '@styles/variables';
@import '@styles/mixins';

.callLayoutWrapper {
  position: relative;
  width: 100%;
  background-color: var(--str-video__video-preview-container--background-color);
  border-radius: var(--str-video__border-radius-sm);
  overflow: hidden;

  @include mobile-all {
    height: 100%;
  }
}

.floatingParticipantWrapper {
  position: absolute;
  right: 0;
  bottom: 8px;
  width: 30%;
  max-width: 300px;
  border-radius: var(--str-video__border-radius-sm);
  box-shadow: 0px 0px 20px var(--blackA7);

  &:hover {
    cursor: move;
  }
}

.participantViewLoading {
  width: 100%;
  aspect-ratio: 16/9;
}
