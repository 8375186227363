.sheetOverlay {
  position: fixed;
  inset: 0;
  z-index: auto;
  animation: fakeAnimation 0.3s forwards; /* Fake animation */
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: -1;
    animation: fadeIn 0.3s forwards;
  }
}

//The fake animation thing is a hack to make sure the overlay contents is visible fully
//when the sheet is open, and the fade is only applied to the background

.sheetOverlay[data-state="open"] {
  animation: fakeAnimationIn 0.3s forwards; /* Fake animation */
  &::before {
    animation: fadeIn 0.3s forwards;
  }
}

.sheetOverlay[data-state="closed"] {
  animation: fakeAnimationOut 0.3s forwards; /* Fake animation */
  &::before {
    animation: fadeOut 0.3s forwards;
  }
}

@keyframes fakeAnimationIn {
  from {
    opacity: 0.99;
  }
  to {
    opacity: 1;
  }
}

@keyframes fakeAnimationOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.99;
  }
}

.sheetContent {
  position: fixed;
  z-index: 50;
  background-color: var(--color-background);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
}

.sheetContent[data-state="open"] {
  animation: slideIn 0.5s forwards;
}

.sheetContent[data-state="closed"] {
  animation: slideOut 0.3s forwards;
}

.sideTop {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 50%;
  border-bottom: 1px solid var(--color-border);
}

.sideTop[data-state="open"] {
  animation: slideInFromTop 0.5s forwards;
}

.sideTop[data-state="closed"] {
  animation: slideOutToTop 0.3s forwards;
}

.sideBottom {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 50%;
  border-top: 1px solid var(--color-border);
}

.sideBottom[data-state="open"] {
  animation: slideInFromBottom 0.5s forwards;
}

.sideBottom[data-state="closed"] {
  animation: slideOutToBottom 0.3s forwards;
}

.sideLeft {
  top: 0;
  bottom: 0;
  left: 0;
  width: 75%;
  max-width: 18rem;
  border-right: 1px solid var(--color-border);
}

.sideLeft[data-state="open"] {
  animation: slideInFromLeft 0.5s forwards;
}

.sideLeft[data-state="closed"] {
  animation: slideOutToLeft 0.3s forwards;
}

.sideRight {
  top: 0;
  bottom: 0;
  right: 0;
  width: 75%;
  max-width: 18rem;
  border-left: 1px solid var(--color-border);
}

.sideRight[data-state="open"] {
  animation: slideInFromRight 0.5s forwards;
}

.sideRight[data-state="closed"] {
  animation: slideOutToRight 0.3s forwards;
}

.sheetClose {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.sheetHeader {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  @media (min-width: 640px) {
    text-align: left;
  }
}

.sheetFooter {
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}

.sheetTitle {
  font-size: 1.125rem;
  font-weight: 600;
  color: var(--text-foreground);
}

.sheetDescription {
  font-size: 0.875rem;
  color: var(--muted-foreground);
}

.visuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* Animations */
@keyframes slideInFromTop {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOutToTop {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOutToBottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
