@import "@styles/mixins";
@import "@styles/variables";

.commandInputWrapper {
  border-bottom: 1px solid;
  height: 2.25rem;
}

.magnifyingGlassIcon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  opacity: 0.5;
}

.commandInput {
  outline: none;
  border: none;
  cursor: text;
  opacity: 1;
  background: transparent;
  width: 100%;
  color: var(--colorV2-black);

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.commandDialogContent {
  // Default styles (non-fullscreen)
  position: relative;
  max-width: 600px;
  border-radius: 12px;
  min-height: 500px;
  max-height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @include mobile-all {
    // Fullscreen styles for mobile
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
    border-radius: 0;
    min-height: 100vh;
    max-height: 100vh;
  }
}

.commandRoot {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.commandList {
  flex: 1;
  overflow-y: auto;
  min-height: 0; // get it to shrink to allowed space
}

.commandEmpty {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  color: var(--text-muted-foreground);
}

.commandGroup {
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--colorV2-grey-dark);
}

.commandSeparator {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  height: 1px;
  background-color: var(--colorV2-grey-dark);
}

.commandItem {
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: background-color 0.2s ease;
  color: var(--colorV2-black);

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &.selected {
    background-color: var(--colorV2-light-green);
    color: var(--colorV2-darkest-green);
  }

  &:hover {
    background-color: var(--colorV2-grey-medium);
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
  cursor: pointer;
}
