/* import breakpoints from '../../styles/breakpoints.module.css'; */
@import "@styles/variables";
@import "@styles/mixins";

.navigationMenuRoot {
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  @include mobile-all {
    justify-content: unset;
  }
}

.navigationMenuList {
  display: flex !important;
  list-style: none;
  margin: 0;
  align-content: center !important;
  @include mobile-all {
    flex-direction: column;
    padding: unset;
  }
}

.mobileSheet {
  display: none;
  @include mobile-all {
    display: block;
    width: 100svw;
    max-width: unset;
  }
}

.navigationMenuContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation-duration: 250ms;
  animation-timing-function: ease;
}
.navigationMenuContent[data-motion="from-start"] {
  animation-name: enterFromLeft;
}
.navigationMenuContent[data-motion="from-end"] {
  animation-name: enterFromRight;
}
.navigationMenuContent[data-motion="to-start"] {
  animation-name: exitToLeft;
}
.navigationMenuContent[data-motion="to-end"] {
  animation-name: exitToRight;
}
@media only screen and (min-width: 600px) {
  .navigationMenuContent {
    width: auto;
  }
}

.navigationmenuLogoLink {
  text-decoration: none;
  color: var(--colorV2-black);
}
.navigationmenuLogoLink:focus {
  color: var(--colorV2-black);
  text-decoration: none;
}
.navigationmenuLogoLink:hover {
  color: var(--colorV2-black);
  text-decoration: none;
}

.navigationMenuItem {
  padding: 0px 12px;
}

.navigatinoMenuIItemDropDown {
  padding: 8px 12px;
}
.navigationMenuGlobeItem {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigationMenuTrigger,
.navigationMenuLink {
  outline: none;
  user-select: none;
  display: block;
  text-decoration: none;
  background: none;
  border: none;
  color: var(--colorV2-black);
  text-align: center;

  /*do not wrap*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.isActive {
    color: var(--colorV2-dark-green);
  }

  @include mobile-all {
    text-align: start;
  }
}
.navigationMenuTrigger:focus,
.navigationMenuLink:focus {
  color: var(--colorV2-dark-green);
  text-decoration: none;
}
.navigationMenuTrigger:hover,
.navigationMenuLink:hover {
  color: var(--colorV2-dark-green);
  text-decoration: none;
}
.navigationMenuTrigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding: 2px 0px 2px 12px;
}

.signupCTALink,
.signupCTALink:hover,
.signupCTALink:focus {
  text-decoration: none;
}

.navigationMenuContent {
  position: absolute;
  top: 0;
  left: 0;
  /*width: 100%;*/
  animation-duration: 250ms;
  animation-timing-function: ease;
}
.navigationMenuContent[data-motion="from-start"] {
  animation-name: enterFromLeft;
}
.navigationMenuContent[data-motion="from-end"] {
  animation-name: enterFromRight;
}
.navigationMenuContent[data-motion="to-start"] {
  animation-name: exitToLeft;
}
.navigationMenuContent[data-motion="to-end"] {
  animation-name: exitToRight;
}

.navigationMenuViewport {
  position: relative;
  transform-origin: top center;
  margin-top: 10px;
  background-color: white;
  border-radius: 6px;
  overflow: hidden;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  height: var(--radix-navigation-menu-viewport-height);
  transition:
    width 300ms ease,
    height 300ms ease;
}
.navigationMenuViewport[data-state="open"] {
  animation: scaleIn 200ms ease;
}
.navigationMenuViewport[data-state="closed"] {
  animation: scaleOut 200ms ease;
}
@media only screen and (min-width: 600px) {
  .navigationMenuViewport {
    /*min-width: 150px;*/
    width: var(--radix-navigation-menu-viewport-width);
  }
}

.hamburgerMenuButton {
  background: none;
  border: none;
  padding: 12px;
}

.hamburgerMenuButton:hover,
.hamburgerMenuButton:focus {
  outline: none;
}

.navigationMenuSubList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.navigationMenuSubMenuItem {
  margin: 0;
  padding: 8px 12px;
  text-align: center;
}

.globeIcon {
  min-width: 15px;
  min-height: 15px;
  margin-right: 4px;
}

.caretDown {
  min-width: 15px;
  min-height: 15px;
  position: relative;
  top: 1px;
  transition: transform 250ms ease;
}
.caretDown[data-state="open"] {
  transform: rotate(180deg);
}

.arrow {
  position: relative;
  top: 70%;
  background-color: white;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-top-left-radius: 2px;
}

.ViewportPosition {
  position: absolute;
  width: 100%;
  top: 100%;
  perspective: 2000px;
}

@keyframes enterFromRight {
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes enterFromLeft {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes exitToRight {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(200px);
  }
}

@keyframes exitToLeft {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: rotateX(-30deg) scale(0.9);
  }
  to {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
}

@keyframes scaleOut {
  from {
    opacity: 1;
    transform: rotateX(0deg) scale(1);
  }
  to {
    opacity: 0;
    transform: rotateX(-10deg) scale(0.95);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.rippleEffect {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
  margin: 1px 6px;
}

.rippleEffect div {
  position: absolute;
  border: 2px solid var(--grey-dark-2);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1300ms cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.rippleEffect div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 16px;
    left: 16px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 34px;
    height: 34px;
    opacity: 0;
  }
}
