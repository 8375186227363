// DropzoneMessage.module.scss

.DropzoneMessage {
  &__icon {
    color: var(--muted-foreground); // Assuming you have a CSS variable or custom color for "text-muted-foreground"
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3); // Equivalent to Tailwind's bg-black bg-opacity-30
    border-radius: 12px; // Equivalent to Tailwind's rounded-xl
    opacity: 0; // Equivalent to Tailwind's opacity-0
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      opacity: 1; // Equivalent to Tailwind's hover:opacity-100
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px; // Equivalent to Tailwind's p-4
  }
}
