@import '@styles/variables';
@import '@styles/mixins';

.fullScreen {
  @include fullScreen;
}

.fullScreenFlexRow {
  @include fullScreen;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  z-index: 1; // this puts the dispaly over background image (coffee cup)

  @include mobile-all {
    flex-direction: column-reverse;
  }
}

.fullScreenFlexColumn {
  @include fullScreen;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rightSideInfo {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 350px;
  width: 100%;
  padding: 24px;

  @include mobile-all {
    max-width: 500px;
  }
}

.headerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.coffeeCupHolder {
  position: fixed;
  margin-right: 10%;
  bottom: 0;
  right: 0;
  width: 30vw;
  max-width: 300px;
  height: 50vh;
  max-height: 400px;
}

.videoLayoutWrapper {

  --video-layout-top-controlls-height: 50px;
  --video-layout-controlls-height: 38px;
  --video-layout-gap: 16px;
  --video-layout-padding-tb: 8px;
  --video-layout-padding-lr: 8px;

  --video-height: calc(
          100vh - var(--video-layout-top-controlls-height) - var(--video-layout-controlls-height) - var(--video-layout-gap) - var(--video-layout-padding-tb) * 2
  );
  max-width: min(1280px, calc(1.778 * var(--video-height)));

  @include mobile-all {
    //--video-layout-padding-tb: 0px;
    //--video-layout-padding-lr: 0px;
    //max-width: 100%;
  }

  margin-top: var(--video-layout-top-controlls-height);
  padding: var(--video-layout-padding-tb) var(--video-layout-padding-lr);
  display: flex;
  flex-direction: column;
  gap: var(--video-layout-gap);
  align-items: center;
  justify-content: center;

  :global(.str-video__participant-view) {
    max-width: revert;
    width: 100%;
    //changing the height here affects the floating video collapsed view (which is hardcoded to a small value)
    //height: 100%;

    @include mobile-all {
      flex: 1 1 400px !important;
    }
  }
}

.videoLayoutWrapperExtended {
  @extend .videoLayoutWrapper;
  flex-grow: 1;
  width: 100%;
  @include non-desktop {
    --video-layout-top-controlls-height: 0px;
  }
}

.videoLayout {
  max-height: var(--video-height);
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile-all {
    justify-content: start;
  }
}

