@import "@styles/variables";
@import "@styles/mixins";

.container {
  height: 100vh;
  background-color: var(--colorV2-light-green);
}

.image {
  @include mobile-all {
    width: 150px;
    height: 150px;
  }
}
