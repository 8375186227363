
.navLink {
  color: var(--colorV2-black);
  text-decoration: none;

  &:hover {
    color: var(--colorV2-dark-green);
  }
  &:focus {
    color: var(--colorV2-dark-green);
  }
}

.activeNavLink {
  color: var(--colorV2-dark-green);
  text-decoration: none;
}
