%darkGreenLink {
  color: var(--colorV2-darkest-green);
  text-decoration: none;
  transition: color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: var(--colorV2-dark-green);
    text-decoration: underline;
  }
}

%darkGreenUnderlineLink {
  color: var(--colorV2-darkest-green);
  text-decoration: underline;
  transition: color 150ms ease-in-out;

  &:hover,
  &:focus {
    color: var(--colorV2-dark-green);
  }
}
