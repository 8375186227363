@import "@styles/variables";
@import "@styles/mixins";

.fullName {
  font-size: var(--font-size-32);

  @include mobile-all {
    font-size: var(--font-size-24);
  }
}
