@import "@styles/variables";

.sliderContainer {
  position: relative;
}

.sliderRoot {
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: 40px;
}

.sliderTrack {
  background-color: var(--green-low);
  position: relative;
  flex-grow: 1;
  border-radius: 9999px;
  height: 12px;
}

.sliderRange {
  position: absolute;
  background-color: var(--green);
  border-radius: 9999px;
  height: 100%;
}

.sliderThumb {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 2px 10px var(--blackA7);
  border-radius: 10px;

  &:hover {
    background-color: var(--green-low);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 5px var(--blackA8);
  }
}

.priceMinLabel,
.priceMaxLabel {
  position: absolute;
  top: 30px;
  left: calc(50% - 25px);
  width: 50px;
  color: var(--green);
  font-weight: 600;
  font-size: var(--font-size-20);
  text-align: center;
  transition: top 0.2s ease;
}
