@import "@styles/mixins";
@import "@styles/variables";

.filterBar {
  background-color: var(--grey-low);
  border-radius: 30px;
  border: 2px var(--gray6) solid;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, WebKit-based browsers */
  }
}

.mobileFilterContent {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: revert;
  max-height: 100%;
  width: 100vw;
  border-radius: 0;
  background-color: white;
  border: 2px var(--gray6) solid;
  box-shadow: 0 2px 10px var(--blackA7);
  animation: dialogContentShow 300ms ease-out;
}

.mobileFilterContent:focus {
  outline: none;
}

.mobileMainCTAs {
  flex: 1;
  width: 50%;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.filterScrollArea {
  flex: 1;

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--spacer-size-5);
  }

  &:after {
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      var(--white) 100%
    );
  }

  &:before {
    top: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      var(--white) 100%
    );
  }

  @include mobile-all {
    width: 100%;
    flex-grow: 1;
    max-height: unset;
  }
}

.filterMenubarContent {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  max-height: 80%;
  min-width: 220px;
  max-width: 500px;
  width: auto; // Default to auto
  background-color: var(--white);
  border-radius: 12px;
  padding: 24px 0;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

  @include mobile-all {
    width: 100%;
  }

  // Modifier for fixedWidth
  &.fixedWidth {
    width: 500px;
  }
}

.commandList {
  [cmdk-list-sizer] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 16px 0 0 0;
  }
}

.comboboxInputWrapper {
  border: 1px solid var(--grey-hsl-0-0-83);
  border-radius: 30px;
  padding: 10px;
  background-color: white;

  &:hover {
    border: 1px var(--green-over) solid;
  }

  &:focus-within {
    border: 1px var(--green-167-37-73) solid;
  }

  button {
    padding: 4px 12px !important;
    font-size: var(--font-size-14);
  }

  [cmdk-input] {
    flex-grow: 1;
    border: 0;
    padding: 5px 10px;
    background-color: transparent;

    &:focus {
      outline: none !important;
    }
  }
}

.filterButton {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
  color: var(--colorV2-black);
  background: var(--colorV2-beige);
}

.filterButton:hover,
.filterButton:focus {
  background: var(--colorV2-beige-over);
}
