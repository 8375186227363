.multiSelectBadge {
  cursor: pointer;
  &:hover {
    background-opacity: 20%;
  }
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
