.paginationContainer {
  padding: 0;
  margin: auto;
}

.page {
  color: var(--color-text);
  float: left;
  padding: 8px 16px;
  text-decoration: none;

  &.isActive {
    background-color: var(--green-low);
  }

  &:hover {
    background-color: var(--green-low-over);
  }
}
