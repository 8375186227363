@import "@styles/variables";
@import "@styles/mixins";

.redTag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  background-color: var(--red-low);
  font-size: var(--font-size-14);
  font-weight: 400;
  color: var(--red-dark);

  &.isLarge {
    font-size: var(--font-size-18);

    @include mobile-all {
      font-size: var(--font-size-14);
    }
  }
}
