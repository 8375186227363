.wave {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0;
  height: 120px;
  margin: 0;
  font-size: 0;
}

.waveTop {
  bottom: 0;
}

.waveBottom {
  bottom: 100%;
}
