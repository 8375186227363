@import '@styles/mixins';
@import '@styles/variables';

.tagsList {
  column-count: 2;
  padding: 0;
  column-gap: 60px;

  li {
    margin-bottom: 8px;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  @include desktop-wide {
    column-count: 3;
    column-gap: 96px;
  }

  @include mobile-portrait {
    column-count: 1;
    list-style: none;
    text-align: center;
    column-gap: 32px;
  }
}
