
.participantView {
  aspect-ratio: 16 / 9;
  height: auto;
}

.collapsedParticipantView {
  @extend .participantView;
  aspect-ratio: inherit;
  height: 46px;
}
