@import "@styles/variables";
@import "@styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 80vh; /* full height minus header */
  overflow: hidden; /* Prevents overflow during animations */
}
@media (max-height: 768px) {
  .container {
    height: auto;
  }
}

.wrapper {
  position: relative; /* Allows content to be positioned absolutely within */
  width: 100%;
  max-width: 1000px;
  height: 100%; /* Maintain height for stacking */
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height: 768px) {
  .wrapper {
    height: auto;
  }
}

.instructionsWrapper,
.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute; /* Stack elements on top of each other */
  top: 10%; /* Control the vertical position */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
  width: 100%;
  max-width: 1000px;
  padding: 0 24px;
  gap: 20px;
  text-align: center;
  min-height: 400px; /* Ensures consistent height */
  overflow: hidden;
}

@media (max-height: 768px) {
  .instructionsWrapper,
  .contentWrapper {
    position: relative;
    top: auto;
    margin-top: 20px;
  }
}

.leadingStatement {
  text-align: center;
  width: 70%;
  display: block;
  padding: 0 24px;
  white-space: normal; /* Allow the text to wrap */
  @include webkit-line-clamp(3);
  @include non-desktop {
    width: 100%;
  }
}

.questionIndicator {
  margin-bottom: 10px;
}

.testContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: relative; /* Keeps content in place */
}

.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  pointer-events: none;

  button,
  .nextButtonWrapper button {
    pointer-events: auto;
  }
}

.nextButtonWrapper {
  margin-left: auto;
}

/* Animation Classes for Instructions */
.instructionsEnter {
  opacity: 0;
  transform: translate(
    -50%,
    20px
  ); /* Start slightly below, center horizontally */
}

.instructionsEnterActive {
  opacity: 1;
  transform: translate(-50%, 0); /* Move into place */
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
}

.instructionsExit {
  opacity: 1;
  transform: translate(-50%, 0); /* Start at normal position */
}

.instructionsExitActive {
  opacity: 0;
  transform: translate(-50%, -20px); /* Move slightly above */
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
}

/* Animation Classes for Questions */
.questionEnter,
.questionExit,
.questionEnterBack,
.questionExitBack {
  position: absolute; /* Keep absolute positioning to ensure smooth transitions */
  width: 100%; /* Ensures the element occupies the full width */
  left: 50%;
  transform: translateX(-50%); /* Center horizontally */
}

.questionEnterActive,
.questionEnterBackActive,
.questionExitActive,
.questionExitBackActive {
  transition:
    opacity 300ms ease-in-out,
    transform 300ms ease-in-out;
}

.questionEnter {
  opacity: 0;
  transform: translate(
    -50%,
    20px
  ); /* Start slightly below, center horizontally */
}

.questionEnterActive {
  opacity: 1;
  transform: translate(-50%, 0); /* Move into place */
}

.questionExit {
  opacity: 1;
  transform: translate(-50%, 0); /* Start at normal position */
}

.questionExitActive {
  opacity: 0;
  transform: translate(-50%, -20px); /* Move slightly above */
}

.questionEnterBack {
  opacity: 0;
  transform: translate(
    -50%,
    -20px
  ); /* Start slightly above, center horizontally */
}

.questionEnterBackActive {
  opacity: 1;
  transform: translate(-50%, 0); /* Move into place */
}

.questionExitBack {
  opacity: 1;
  transform: translate(-50%, 0); /* Start at normal position */
}

.questionExitBackActive {
  opacity: 0;
  transform: translate(-50%, 20px); /* Move slightly below */
}
