.fadeInContainer {
  animation: fade-in 400ms;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.indicator {
background: #3b82f6;
width: 100%;
          height: 100%;
                     transform-origin: left;
animation: indeterminate 1.5s infinite linear;
}

@keyframes indeterminate {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
