.dropdownMenuItem {
  color: var(--colorV2-darkest-green);
  transition: color 0.2s ease;
  svg {
    width: 20px;
    height: 20px;
  }
}

.dropdownMenuItem[data-highlighted] {
  color: var(--colorV2-dark-green);
  background-color: var(--colorV2-grey-light-medium);
}

.dropdownTrigger {
  color: var(--colorV2-darkest-green);
  // this messes with the collapse animation, we should figure this out if we want both animations
  // transition: color 0.2s ease;
  cursor: pointer;
}

/* For hover state */
.dropdownTrigger:hover {
  color: var(--colorV2-dark-green);
  background-color: var(--colorV2-grey-light-medium);
}

/* For open state */
.dropdownTrigger[data-state="open"] {
  color: var(--colorV2-dark-green);
  background-color: var(--colorV2-grey-light-medium);
}
