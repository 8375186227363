@import "@styles/mixins";
@import "@styles/variables";

.lineClamp {
  @include webkit-line-clamp(var(--clamp-lines, 1));
}

.grey {
  color: var(--colorV2-grey-dark);
}
