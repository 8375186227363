.navLink {
  color: var(--colorV2-black);
  text-decoration: none;
  transition: color 0.2s ease;

  &:hover {
    color: var(--colorV2-dark-green);
    background-color: var(--colorV2-grey-light-medium);
  }
  &:focus {
    color: var(--colorV2-darkest-green);
    background-color: var(--colorV2-grey-light-medium);
  }
}

.active {
  font-weight: 500;
  color: var(--colorV2-dark-green) !important;
}
