.buttonBase {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
}

.ghost {
  /* reset these because they are actually offset by negative margin by radix */
  padding-left: var(--button-ghost-padding-x);
  padding-right: var(--button-ghost-padding-x);

  outline: none;
  background: none;
  border: none;
}

.ghostMint {
  color: var(--colorV2-darkest-green);

  &:hover,
  &:focus {
    color: var(--colorV2-dark-green);
  }
}

.ghostGrey {
  color: var(--colorV2-black);

  &:hover {
    color: var(--colorV2-dark-green);
  }
}

.outlineLightMint {
  color: var(--colorV2-black);
  border: 2px solid var(--colorV2-light-green);
  box-shadow: none;
  font-weight: 400;
  transition: background-color 300ms ease;

  a {
    color: var(--colorV2-black);
    text-decoration: none;
  }

  &:hover {
    background-color: var(--colorV2-light-green);
  }
}

.fullWidth {
  width: 100%;
}
