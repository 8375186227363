/* Breadcrumbs.module.scss */

.breadcrumbItem {
  display: inline-flex;
  align-items: center;
  gap: 0.375rem;

  @media (min-width: 640px) {
    gap: 0.625rem;
  }
}

.breadcrumbLink {
  transition: color 150ms ease-in-out;

  &:hover {
    color: var(--accent);
  }
}

.breadcrumbPage {
  font-weight: 400;
  color: var(--foreground);
}

.breadcrumbSeparator {
  display: flex;
  align-items: center;

  svg {
    width: 0.875rem;
    height: 0.875rem;
  }
}

.breadcrumbEllipsis {
  display: flex;
  height: 2.25rem;
  width: 2.25rem;
  align-items: center;
  justify-content: center;
}

.breadcrumbEllipsisIcon {
  height: 1rem;
  width: 1rem;
}
