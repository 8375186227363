@import "@styles/mixins";
@import "@styles/placeholders";

.container {
  position: relative;

  &.waveLayout {
    padding-bottom: 100px;

    &.bigWaveLayout {
      padding-bottom: 180px;

      @include tablet {
        padding-bottom: 150px;
      }

      @include mobile-all {
        padding-bottom: 100px;
      }
    }

    &.imageOverlappingWaveLayout {
      padding-bottom: 0;
    }
  }

  &.bigWaveLayout {
    .wave {
      height: 250px;

      @include tablet {
        height: 200px;
      }

      @include mobile-all {
        height: 120px;
      }
    }
  }

  &.bigImageLayout {
    .responsiveImage {
      margin-top: 12px;
      max-height: 450px;

      @include tablet {
        max-height: 250px;
      }

      @include mobile-all {
        max-height: 195px;
      }
    }
  }
}

.header {
  position: relative;
  overflow: hidden;
}

.responsiveImage {
  width: 100%; /* Ensure the image can scale up to the width of its container */
  object-fit: contain; /* Maintains aspect ratio within the given dimensions */
  height: auto;
  max-height: 350px;
  z-index: 1; /* Ensure the image is above the wave */

  @include tablet {
    max-height: 200px;
  }

  @include mobile-all {
    max-height: 120px;
  }
}

.text {
  a:not([type="button"]) {
    @extend %darkGreenUnderlineLink;
  }
}
