.multiUpload {
  .dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem;
    border: 2px dashed var(--color-border);
    border-radius: 0.5rem;
    background-color: var(--gray-1);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: var(--gray-3);
    }
  }

  .dropzoneIconContainer {
    border: 1px solid var(--color-border);
    padding: 0.5rem;
    border-radius: 0.375rem; /* Equivalent to rounded-md */
    max-width: min-content;
    margin: 0 auto;
  }

  .dropzoneText {
    color: var(--colorV2-grey-dark);
  }

  .hiddenInput {
    display: none;
  }

  .filesContainer {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .fileCard {
    display: flex;
    justify-content: space-between;
    gap: 0.75rem;
    align-items: center;
  }

  .iconButton {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
